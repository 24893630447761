'use client';

import { ContentfulImage } from '@components/ContentfulImage';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useSiteInfo } from '@context/siteInfoContext';
import { Carousel as NGGCarousel } from '@ngg/components';
import type {
    EditorialGallery as IEditorialGallery,
    EditorialSplash,
} from '@server/gql/graphql';
import { useEffect, useRef, useState } from 'react';

import { cn } from '@/lib/utils';

const PrevButton = ({
    text,
    isVisible,
    onClick,
}: {
    text: string;
    isVisible: boolean;
    onClick: () => void;
}) => (
    <div className="pointer-events-none absolute left-4 right-4 top-0 z-10 flex aspect-video max-w-[1200px] items-center md:mx-auto">
        <button
            type="button"
            onClick={onClick}
            className={cn(
                'pointer-events-auto ml-2 flex h-6 w-6 items-center justify-center bg-white/50 text-black md:ml-4',
                !isVisible ? 'cursor-default opacity-50' : 'hover:bg-white',
            )}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none">
                <path d="M9 17L1 9L9 1" stroke="black" />
            </svg>
            <span className="sr-only">{text}</span>
        </button>
    </div>
);

const NextButton = ({
    text,
    isVisible,
    onClick,
}: {
    text: string;
    isVisible: boolean;
    onClick: () => void;
}) => (
    <div className="pointer-events-none absolute left-4 right-4 top-0 z-10 flex aspect-video max-w-[1200px] items-center justify-end md:mx-auto">
        <button
            type="button"
            onClick={onClick}
            className={cn(
                'pointer-events-auto mr-2 flex h-6 w-6 items-center justify-center bg-white/50 text-black md:mr-4',
                !isVisible ? 'cursor-default opacity-50' : 'hover:bg-white',
            )}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none">
                <path d="M1 1L9 9L1 17" stroke="black" />
            </svg>
            <span className="sr-only">{text}</span>
        </button>
    </div>
);

const setSlideItem = (
    entry: IntersectionObserverEntry,
    setActiveIndex: (index: number) => void,
) => {
    const target = entry.target as HTMLElement;

    if (entry.intersectionRatio > 0.5) {
        setActiveIndex(Number(target.dataset.index));
    }
};

const GalleryItem = ({
    item,
    index,
    activeIndex,
}: {
    item: EditorialSplash;
    index: number;
    activeIndex: number;
}) => (
    <div
        className={cn(
            'transition-opacity duration-200',
            index === activeIndex
                ? 'opacity-100'
                : 'opacity-100 delay-200 md:opacity-60',
        )}>
        {(item?.mobileImage || item?.desktopImage) && (
            <div className="relative aspect-video w-full">
                <ContentfulImage
                    className="object-cover"
                    // @todo Do not use any
                    mobileImage={item.mobileImage as any}
                    desktopImage={item.desktopImage as any}
                />
            </div>
        )}
        {item?.imageText?.json ? (
            <p className="py-1 text-sm text-grey-300">
                {documentToPlainTextString(item?.imageText.json)}
            </p>
        ) : null}
    </div>
);

export default function EditorialGallery({
    imagesCollection,
}: IEditorialGallery) {
    const ref = useRef<HTMLDivElement>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [nextButtonVisibility, setNextButtonVisibility] = useState(true);
    const items = imagesCollection?.items.filter((item) => item?.media?.src);
    const { globalDictionary } = useSiteInfo();

    useEffect(() => {
        if (!ref.current) return undefined;

        const observer = new IntersectionObserver(
            ([entry]) => setSlideItem(entry, setActiveIndex),
            {
                root: ref.current,
                rootMargin: '0px',
                threshold: [0.5],
            },
        );

        Array.from(ref.current.children).forEach((child) => {
            observer.observe(child);
        });

        return () => observer.disconnect();
    }, []);

    const previous = () => {
        if (!ref.current) return;

        const currentChild = ref.current?.children[
            Math.max(0, activeIndex - 1)
        ] as HTMLDivElement;
        ref.current.scrollTo({
            left: currentChild?.offsetLeft,
            behavior: 'smooth',
        });
        setActiveIndex(Math.max(0, activeIndex - 1));
        setNextButtonVisibility(
            Math.max(0, activeIndex - 1) < ref.current.children.length - 1,
        );
    };

    const next = () => {
        if (!ref.current) return;

        // Don't scroll if we're at the end of the carousel.
        if (activeIndex === ref.current.children.length - 1) return;

        const currentChild = ref.current?.children[
            Math.min(activeIndex + 1, ref.current.children.length)
        ] as HTMLDivElement;

        ref.current.scrollTo({
            left: currentChild?.offsetLeft,
            behavior: 'smooth',
        });
        setActiveIndex(Math.min(activeIndex + 1, ref.current.children.length));
        setNextButtonVisibility(
            Math.min(activeIndex + 1, ref.current.children.length) <
                ref.current.children.length - 1,
        );
    };

    if (!items?.length) return null;

    return (
        <NGGCarousel
            ref={ref}
            PreviousButton={() =>
                PrevButton({
                    text: globalDictionary?.previousSlide,
                    isVisible: activeIndex > 0,
                    onClick: previous,
                })
            }
            NextButton={() =>
                NextButton({
                    text: globalDictionary?.nextSlide,
                    isVisible: nextButtonVisibility,
                    onClick: next,
                })
            }
            classNames={{
                wrapper:
                    'not-prose max-w-none overflow-hidden [--gap:0.75rem] [--slides-per-view:1] md:[--slides-per-view:1.15] px-4 md:px-0',
                slider: 'scrollbar-none',
                item: 'first:ml-[max(0rem,_calc((100%_-_1200px)_/_2))] md:snap-center max-w-[min(100%,_1200px)] basis-[max(calc((100%_-_((var(--slides-per-view)_-_1)_*_var(--gap)))_/_var(--slides-per-view)),_1200px)]',
            }}>
            {items.map((item, i) => {
                if (!item) return null;
                return (
                    <GalleryItem
                        key={item.sys.id}
                        item={item}
                        index={i}
                        activeIndex={activeIndex}
                    />
                );
            })}
        </NGGCarousel>
    );
}
